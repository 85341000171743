@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-family: 'quark';
  position: fixed;
  overflow: hidden;
  width: 100vw;
  background-color: #fffef1;
}

#root {
  height: 100vh;
}

#sharable-canvas-container {
  width: 100%;
}

@layer components {
  .container {
    @apply relative mx-auto max-w-[428px];
  }

  .loading-animation-bg {
    background-image: url('/public/img/home/onetouch-31.png');
  }

  .desktop-bg {
    background-image: url('/public/img/ui-desktop-1.png');
    @apply bg-cover bg-fixed bg-center bg-no-repeat;
  }

  .main-bg {
    background-image: url('/public/img/home/onetouch-24.png');
    @apply bg-cover bg-fixed bg-center bg-no-repeat;
  }

  .measure-his-bg {
    background: url('/public/img/measure/onetouch-56.png') center top,
      url('/public/img/measure/onetouch.png') center center,
      url('/public/img/measure/onetouch-58.png') center center;
    @apply bg-cover bg-no-repeat;
  }

  .wake-him-up-bg {
    background-image: url('/public/img/measure/onetouch-51.png');
    @apply bg-cover bg-left-top bg-repeat-y;
  }

  .id-card {
    background-image: url('/public/img/measure/onetouch-45.png');
    @apply bg-contain bg-center bg-no-repeat;
  }

  .measure-warning-modal-bg {
    background-image: url('/public/img/measure/onetouch-52.png');
    @apply bg-cover bg-top bg-no-repeat;
  }

  .summary-share-image-bg {
    background-image: url('/public/img/measure/summary/onetouch-66.png');
    @apply bg-contain bg-[center_-10px] bg-no-repeat;
  }

  .summary-bg {
    background-image: url('/public/img/measure/summary/onetouch-59.png');
    @apply bg-contain bg-[center_-44px] bg-repeat-y;
  }

  .summary-if-you-do-not-believe-bg {
    background-image: url('/public/img/measure/summary/onetouch.png');
    @apply bg-contain bg-top bg-no-repeat;
  }

  @media (orientation: landscape) {
    #horizontal-guard {
      @apply hidden;
    }
  }

  @media (orientation: landscape) {
    #horizontal-guard {
      @apply z-50 fixed text-lg bg-main-black top-0 left-0 w-full h-full flex justify-center items-center text-main-yellow;
    }
  }
}