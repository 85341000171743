.indicator {
  position: relative;
  width: 50px;
  height: 50px;
  transform: rotate(45deg) scale(0.6);
}

.indicator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  animation: animate 1s linear infinite;
}

.indicator span:nth-child(1) {
  top: -20px;
  left: -20px;
  animation-delay: 0s;
}

.indicator span:nth-child(2) {
  top: -10px;
  left: -10px;
  animation-delay: 0.2s;
}

.indicator span:nth-child(3) {
  top: 0;
  left: 0;
  animation-delay: 0.4s;
}

.indicator span:nth-child(4) {
  top: 10px;
  left: 10px;
  animation-delay: 0.6s;
}

.indicator span:nth-child(5) {
  top: 20px;
  left: 20px;
  animation-delay: 0.8s;
}


@keyframes animate {
  0% {
    border-color: #fff;
    transform: translate(0, 0);
  }

  20% {
    border-color: #fff;
    transform: translate(10px, 10px);
  }

  20.1%,
  100% {
    border-color: #ffd64a;
  }
}